import * as React from 'react';
import { Spin } from 'antd';

// COMPONENT
import DialogCam from '../DialogCam';
// API
import { getCamSnapshot } from '../../../api';

// IMAGE
import disconnectIcon from '../../../assets/images/ic-no-connect.svg';
import camLoading from '../../../assets/images/ic-cam-loading.svg';
import iconArrowRightBlack from '../../../assets/images/icon_arrow_right_black.svg';
import iconArrowLeftRed from '../../../assets/images/icon_arrow_left_red.svg';
import iconArrowLeftGray from '../../../assets/images/icon_arrow_left_gray.svg';

import { CamBox, CamImgWrap, CamLoading, CamImg, CamNumber, CamTitle, PureperdName, CamTitleTxt, CamSubTxt, DetailTime } from './index.styled';
import axios from 'axios';
import { JellyDialog } from '../../../components';

export default function CamItem(props) {
	const [state, setState] = React.useState({
		imageURL: disconnectIcon,
		isConnect: false,
		momWatching: 'N',
		famWatching: 'N',
		momHours: 0,
		momMinutes: 0,
		momSeconds: 0,
		famHours: 0,
		famMinutes: 0,
		famSeconds: 0,
		famWatchingTimeList: [],
		isMaster: 'N',
		viewAble: 'N',
	});
	const [showModal, setShowModal] = React.useState(false);
	const [imageLoading, setImageLoading] = React.useState(true);
	const [imageError, setImageError] = React.useState(false);
	const [momConnect, setMomConnect] = React.useState(false);
	const [isDetailTimesBox, setIsDetailTimesBox] = React.useState(false);

	React.useEffect(() => {
		function _bootstrap() {
			if (props.camImg.startsWith('rtsp')) {
				axios
					.post('https://ffmpeg.jellyapi.com/camera/snapshot', { url: props.camImg, type: 'RTSP' })
					.then((data) => data.data)
					.then((res) => {
						setState({
							imageURL: `https://img.jellyview.co.kr/unsafe/${res}?time=${new Date().getTime()}`,
							isConnect: true,
						});
					})
					.catch(() => {
						setImageLoading(false);
					});
			} else {
				getCamSnapshot(props.cameraUid).then(function(res) {
					if (res.period.cameraImg) {
						setState({
							imageURL: res.period.cameraImg,
							isConnect: true,
						});
					} else {
						setImageLoading(false);
					}
				});
			}
		}

		if (props.momUid !== null && props.momUid !== '') {
			setMomConnect(true);
		}
		_bootstrap();
	}, []);

	React.useEffect(() => {
		if (props.isMaster === 'N') {
			if (props.viewAble === 'Y') {
				setImageError(false);
			} else {
				setImageError(true);
			}
		}
	});

	const { famHours, famMinutes, famSeconds, momHours, momMinutes, momSeconds, famWatchingTimeList, viewAble } = props;

	function modalViewAble() {
		if (props.isMaster === 'N' && props.viewAble === 'N') {
			JellyDialog.info({
				content: '현재 젤리캠 운영시간이 아닙니다. \n캠 화면은 운영시간에만 시청이 가능합니다.',
			});
		}
	}

	function modalShow() {
		if (!showModal && state.isConnect) {
			setShowModal(true);
		}
	}

	function modalHide() {
		if (showModal) {
			setShowModal(false);
		}
	}
	function onLoadEndImage() {
		if (imageLoading) {
			setImageLoading(false);
		}
	}
	function onLoadErrorImage() {
		setImageError(true);
		if (state.isConnect) {
			setState({
				...state,
				imageURL: disconnectIcon,
			});
		}
	}
	function onClickTitle() {
		props.onClick(props.cameraUid, props.momUid, props.cameraName);
	}

	function assembleMonitoringTime(hour, min, sec, type = 'type1') {
		hour = Number(hour);
		min = Number(min);
		sec = Number(sec);

		let times = '';

		if (type === 'type2') {
			times = `${replaceNum2Level(hour || 0)}:`;
		} else {
			times = `${replaceNum2Level(hour || 0)}시간 `;
		}

		if (hour === 0 && min === 0 && sec > 0) {
			times = `${times}01${type === 'type1' ? '분' : ''}`;
		} else {
			times = `${times}${replaceNum2Level(min || 0)}${type === 'type1' ? '분' : ''}`;
		}

		return times;
	}

	function replaceNum2Level(num) {
		const str = num.toString();
		return Number(str) < 10 ? '0' + str : str;
	}

	function familyType(relGbnCd) {
		switch (relGbnCd) {
			case '2':
				return '아빠';
			case '3':
				return '조부모';
			case '4':
				return '친척';
			case '5':
				return '친구';
			case '9':
				return '기타';
			case '10':
				return '친할머니';
			case '11':
				return '친할아버지';
			case '12':
				return '외할머니';
			case '13':
				return '외할아버지';
			default:
				return '기타';
		}
	}

	return (
		<CamBox className="camBox">
			{showModal && <DialogCam visible={showModal} title={momConnect ? props.momName : '미연결'} camUrl={props.camUrl} onPressClose={modalHide} />}
			<CamImgWrap
				className="camImgWrap"
				isConnect={state.isConnect}
				isMaster={props.isMaster}
				viewAble={props.viewAble}
				isErr={imageError}
				onClick={modalViewAble}
			>
				<Spin spinning={imageLoading} indicator={<CamLoading src={camLoading} />}>
					{state.isConnect && !imageError && (
						<CamImg
							onClick={modalShow}
							className="camImg"
							src={props.isMaster === 'N' && props.viewAble === 'N' ? '' : state.imageURL}
							onLoad={onLoadEndImage}
							onError={onLoadErrorImage}
							alt="캠 연결 이미지"
						/>
					)}
					<CamNumber
						className={`camNumber ${state.isConnect && !imageError ? 'isCam' : ''} ${
							props.momWatching === 'Y' || props.famWatching === 'Y' ? 'isLive' : ''
						}`}
					>
						{props.cameraName}
					</CamNumber>
				</Spin>
			</CamImgWrap>

			{/* 시청시간 */}
			{state.isConnect && !imageError && (
				<DetailTime className={isDetailTimesBox ? 'open' : ''}>
					<div className="total_time_box">
						<div className={`total_info_box mom ${props.momWatching === 'Y' ? 'isLive' : ''}`}>
							<span className="type">산모</span>
							<span className="time">{assembleMonitoringTime(momHours, momMinutes, momSeconds, 'type1')}</span>
						</div>
						<button
							type="button"
							className={`total_info_box family ${props.famWatching === 'Y' ? 'isLive' : ''}`}
							onClick={() => setIsDetailTimesBox(true)}
						>
							<span className="type">가족</span>
							<span className="time">
								<span>{assembleMonitoringTime(famHours, famMinutes, famSeconds, 'type1')}</span>
								<span className="detail_text">
									자세히{' '}
									<i className="img_box">
										<img src={iconArrowRightBlack} alt="" />
									</i>
								</span>
							</span>
						</button>
					</div>
					<div className={`family_detail_box ${props.famWatching === 'Y' ? 'isLive' : ''}`}>
						<div className="header">
							<p>가족 시청시간</p>
							<button type="button" onClick={() => setIsDetailTimesBox(false)}>
								<i className="img_box">
									<img src={props.famWatching === 'Y' ? iconArrowLeftRed : iconArrowLeftGray} alt="" />
								</i>
								<span>닫기</span>
							</button>
						</div>
						{famWatchingTimeList.length > 0 ? (
							<ul className="family_list">
								{famWatchingTimeList.map((family, index) => (
									<li key={index} className={family.watchingYn === 'Y' ? 'isLive' : ''}>
										<span className="family_type">{familyType(family.regGbnCd)}</span>
										<span className="family_time">{assembleMonitoringTime(family.hours, family.minutes, family.seconds, 'type2')}</span>
									</li>
								))}
							</ul>
						) : (
							<ul className="family_list">
								<span>금일 시청한 가족이 없습니다.</span>
							</ul>
						)}
					</div>
				</DetailTime>
			)}

			<CamTitle
				className={`camTitle ${momConnect ? 'isMom' : ''} ${props.momWatching === 'Y' || props.famWatching === 'Y' ? 'isLive' : ''}`}
				onClick={onClickTitle}
			>
				<PureperdName isConnect={state.isConnect} isErr={imageError}>
					{momConnect ? props.momName : '미연결'}
				</PureperdName>
				{momConnect && <CamTitleTxt>산모님</CamTitleTxt>}
				{/* 20230104 jhlee 캠 가족 추가 신청 수 추가 */}
				{momConnect && <CamSubTxt>{props.partyCnt}개연결</CamSubTxt>}
			</CamTitle>
		</CamBox>
	);
}

CamItem.defaultProps = {
	momUid: '',
	momName: '미연결',
	cameraUid: '',
	cameraName: '',
	partyCnt: 0,
};
