// @ts-nocheck

//REACT
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// COMPONENT
import CamItem from './CamItem';
import DialogCamConnect from './DialogCamConnect';
//import DialogCamConnect from "../../components/previous/Dialog/DialogCamConnect";

// STYLE
import styled from 'styled-components';
import { careToast, CareLoading } from '../../style/jellybook-component';
import { JellyContainer, JellyContainerTop, CountNumber, CountTxt, CountBox, CamBox, CamOnBox, CamOffBox } from '../../style';

//IMAGE
import cameraOnIcon from '../../assets/images/ic-category-cam-on.svg';
import cameraOffIcon from '../../assets/images/ic-category-cam-off.svg';
import cameraNoDataIcon from '../../assets/images/ic-cam-list-none.svg';

// API
import { getCamList, getMasterOptionAPI } from '../../api';

// TYPE
import { ICamListData } from '../../api/cam';

// UTIL
import { httpError } from '../../services';
import useAuthStore from '../../store/auth/authStore';
import { JellyAlert } from '../../components';
import useInterval from './useInterval';

const Cam: React.FC = () => {
	const history = useHistory();
	const [connectCount, setConnectCount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [disConnectCount, setDisConnectCount] = useState<number>(0);
	const [camList, setCamList] = useState<ICamListData[]>([]);
	const [selectedCamName, setSelectedCamName] = useState<string>('');
	const [selectedCamUid, setSelectedCamUid] = useState<string>('');
	const [selectedCamUrl, setSelectedCamUrl] = useState<string>('');
	const [selectedMomUid, setSelectedMomUid] = useState<string | null>(null);
	const [camConnectVisible, setCamConnectVisible] = useState<boolean>(false);
	const [timerInterval, setTimerInterval] = useState<number>(0);
	const { pccIndex } = useAuthStore();
	const [viewAble, setViewAble] = useState<string>('N');
	const [isMaster, setIsMaster] = useState<string>('N');
	const [isPlay, setIsPlay] = useState<boolean>(false);
	const data = new Array(24);
	for (let i = 0; i < data.length; i++) {
		data[i] = '';
	}

	// 리스트 api
	const renderCamList = async () => {
		setIsLoading(true);
		try {
			const {
				period: { cameraConnectCnt, cameraDisConnectCnt, cameraList, viewAble },
			} = await getCamList();
			setViewAble(viewAble);
			setConnectCount(cameraConnectCnt);
			setDisConnectCount(cameraDisConnectCnt);
			setCamList(cameraList);
		} catch ({ code }) {
			httpError(history, code);
		} finally {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const renderIntervalCamList = async () => {
		try {
			const {
				period: { cameraConnectCnt, cameraDisConnectCnt, cameraList, viewAble },
			} = await getCamList();
			setViewAble(viewAble);
			setConnectCount(cameraConnectCnt);
			setDisConnectCount(cameraDisConnectCnt);
			setCamList(cameraList);
		} catch ({ code }) {
			httpError(history, code);
			setIsPlay(true);
		}
	};

	const loadMasterOption = async () => {
		try {
			const {
				period: { masterYn },
			} = await getMasterOptionAPI();
			setIsMaster(masterYn);
			console.log('masterYn: ', masterYn);
		} catch ({ code }) {
			httpError(history, code);
		}
	};

	const handleSuccessConnect = (message: string) => {
		careToast(message);
		renderCamList();
	};

	const customInterval = useInterval(
		() => {
			console.log('interval');
			renderIntervalCamList();
		},
		isPlay ? null : 45000,
	);

	useEffect(() => {
		console.log('돌아');
		renderCamList();
		loadMasterOption();
	}, [pccIndex]);

	useEffect(() => {
		setTimerInterval(customInterval);
	}, [camList]);

	const handleClickCam = (camUid: string, momUid: string | null, camName: string, camUrl: string) => {
		setSelectedCamName(camName);
		setSelectedMomUid(momUid);
		setSelectedCamUid(camUid);
		setSelectedCamUrl(camUrl);
		setCamConnectVisible(true);
	};

	return (
		<>
			<JellyContainer>
				<CareLoading visible={isLoading} fill />
				<JellyContainerTop style={{ position: 'relative' }}>
					{camList.length > 0 && (
						<CountBox>
							<CountPrependImage src={cameraOnIcon} alt="연결중" />
							<ConnectCountNumber>{connectCount}</ConnectCountNumber>
							<ConnectCountTxt>연결중</ConnectCountTxt>
						</CountBox>
					)}
					<CountBox>
						<CountPrependImage src={cameraOffIcon} alt="미연결" />
						<CountNumber>{disConnectCount}</CountNumber>
						<CountTxt>미연결</CountTxt>
					</CountBox>

					{/* 방송중/쉬는중 버튼 */}
					<CamBox>{viewAble === 'Y' ? <CamOnBox>방송중</CamOnBox> : <CamOffBox>쉬는중</CamOffBox>}</CamBox>
				</JellyContainerTop>
				<CamListWrap noData={camList.length <= 0}>
					{camList.map((item) => (
						<CamItem key={item.cameraUid} {...item} isMaster={isMaster} viewAble={viewAble} onClick={handleClickCam} />
					))}
					{camList.length <= 0 && (
						<NoData>
							<img src={cameraNoDataIcon} alt="연결된 캠이 없습니다." />
							<p>연결된 캠이 없습니다.</p>
						</NoData>
					)}
				</CamListWrap>
				<DialogCamConnect
					visible={camConnectVisible}
					onPressClose={() => setCamConnectVisible(false)}
					camName={selectedCamName}
					camUid={selectedCamUid}
					momUid={selectedMomUid ? selectedMomUid : undefined}
					onSuccess={handleSuccessConnect}
				/>
			</JellyContainer>
		</>
	);
};

interface IContainerProps {
	noData: boolean;
}

const NoData = styled.div`
	width: 126px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	img {
		width: 50px;
		height: 50px;
		margin-bottom: 24px;
	}
	p {
		font-size: 14px;
		color: #bababa;
	}
`;

const ConnectCountNumber = styled(CountNumber)`
	& {
		color: #e55863;
	}
`;
const ConnectCountTxt = styled(CountTxt)`
	& {
		color: #151515;
	}
`;

const CountPrependImage = styled.img`
	width: 16px;
	height: 20px;
	margin: 0 7px 3px 0;
`;

const CamListWrap = styled.div`
	${(props: IContainerProps) => (props.noData ? 'min-height: 531px; position: relative;' : '')}
	padding: 25px;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 12px;
	/* &:after {
		clear: both;
		content: ' ';
		display: block;
	} */
`;

export default Cam;
