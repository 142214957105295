// @ts-nocheck

import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { JellyButton, JellyLoading } from '../../components';
import { Table, Radio, Pagination } from 'antd';
import plusIcon from '../../assets/images/ic-plus.svg';
import NodataCustomer from '../../assets/images/ic-cus-list-none.svg';

//API
import { getCustomerList } from '../../api';

//TYPE
import { ICustomerInfo } from '../../api/customer';
import { RadioChangeEvent } from 'antd/lib/radio';

//CONTEXT
import { useCustomerInfo, useJellybookGlobal } from '../../contexts';

//SERVICES
import { httpError } from '../../services';

import keywordDeleteIcon from '../../assets/images/ic-search-close.svg';
import searchIcon from '../../assets/images/ic-search.svg';
import useAuthStore from '../../store/auth/authStore';
import moment from 'moment';

interface ICustomerPayload {
	page: number;
	checkin?: 'Y' | 'N' | 'ALL' | null;
}

const Customer: React.FC = () => {
	const {
		state: { modified, refresh },
		dispatch,
	} = useCustomerInfo();
	const {
		globalState: { searchKeyword },
		globalDispatch,
	} = useJellybookGlobal();
	const history = useHistory();
	const { pccIndex } = useAuthStore();
	const [totalCount, setTotalCount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [customerList, setCustomerList] = useState<ICustomerInfo[]>([]);
	const [customerPayload, setCustomerPayload] = useState<ICustomerPayload>({
		page: 1,
		checkin: 'Y',
	});
	const [visibleSearchKeywordDelete, setVisibleSearchKeywordDelete] = useState<boolean>(false);
	const [checkKeyword, setCheckKeyword] = useState<string>('');

	const handleShowAddDialog = () =>
		dispatch({
			type: 'ADD',
		});

	const handleSelectCheckIn = (e: RadioChangeEvent) => {
		setCustomerPayload({
			checkin: e.target.value,
			page: 1,
		});
		dispatch({ type: 'REFERSH' });
	};

	// ----------------------------------------- //

	const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			globalDispatch({
				type: 'SEARCH',
				keyword: checkKeyword,
			});
		}
	};

	const handleChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCheckKeyword(e.currentTarget.value);
		if (e.currentTarget.value.length >= 1) {
			setVisibleSearchKeywordDelete(true);
		} else {
			setVisibleSearchKeywordDelete(false);
		}
	};
	const handleDeleteKeyword = () => {
		setVisibleSearchKeywordDelete(false);
		setCheckKeyword('');
	};

	// const handleShowDownloadDialog = () =>
	//   dispatch({
	//     type: "DOWNLOAD",
	//     checkin: customerPayload.checkin,
	//     searchVal: searchKeyword
	//   });

	const updateCustomerList = () => {
		if (modified) {
			const updatedList = customerList
				.map((item) => {
					if (item.customerUid === modified.customerUid) {
						item = {
							...modified,
							key: modified.customerUid,
						};
					}
					return item;
				})
				.filter((item) => item.checkin === customerPayload.checkin);
			setCustomerList(updatedList);
		}
	};
	const renderCustomerList = useCallback(async () => {
		setIsLoading(true);
		try {
			const { totalCnt, period } = await getCustomerList({
				...customerPayload,
				searchVal: searchKeyword,
			});
			const dataWithkey = period.map((item) => {
				item.key = item.customerUid;
				item.checkin = item.checkin == 'Y' ? '입실' : '미입실';
				return item;
			});
			setCustomerList(dataWithkey);
			setTotalCount(totalCnt);
		} catch ({ code }) {
			httpError(history, code);
		} finally {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerPayload, searchKeyword, refresh]);

	useEffect(() => {
		renderCustomerList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [renderCustomerList, pccIndex]);

	useEffect(() => {
		updateCustomerList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modified]);

	useEffect(() => {
		dispatch({ type: 'HIDE' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = [
		{
			title: '산모이름',
			name: 'name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: '입실',
			name: 'checkin',
			dataIndex: 'checkin',
			key: 'checkin',
		},
		{
			title: '전화번호',
			name: 'phone',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: '생년월일',
			name: 'birth',
			dataIndex: 'birth',
			key: 'birth',
		},
		{
			title: '아기이름 01',
			name: 'baby1',
			dataIndex: 'baby1',
			key: 'baby1',
		},
		{
			title: '아기이름 02',
			name: 'baby2',
			dataIndex: 'baby2',
			key: 'baby2',
		},
		{
			title: '아기이름 03',
			name: 'baby3',
			dataIndex: 'baby3',
			key: 'baby3',
		},
		{
			title: '아기이름 04',
			name: 'baby4',
			dataIndex: 'baby4',
			key: 'baby4',
		},
	];
	return (
		<>
			{' '}
			<CustomerContainer>
				<JellyLoading visible={isLoading} fill />
				<CustomerTopNoti>
					<NotiText1>통신사 변경 일정이 있을 경우, 젤리캠 고객센터로 미리 연락 바랍니다. (3~4일전)</NotiText1>
					<NotiText2>(사전 연락 없이 통신사가 변경될 경우 젤리캠 송출이 안될 수 있으며, 복구 처리가 지연될 수 있습니다.)</NotiText2>
				</CustomerTopNoti>
				<CustomerTop>
					<CountBox>
						<CountNumber>{totalCount}</CountNumber>
						<CountTxt>명의 고객이 있습니다.</CountTxt>
					</CountBox>
					<SearchWrap>
						<SearchInput
							type="text"
							placeholder="검색어를 입력해주세요."
							onChange={handleChangeKeyword}
							onKeyPress={handleSearch}
							value={checkKeyword}
							autoComplete={'off'}
						/>
						{visibleSearchKeywordDelete && <SearchKeywordReset onClick={handleDeleteKeyword} />}
					</SearchWrap>
					<TableOptionWrap>
						<Radio.Group onChange={handleSelectCheckIn} value={customerPayload.checkin}>
							<Radio value="ALL">전체보기</Radio>
							<Radio value="Y">입실만 보기</Radio>
						</Radio.Group>
						{/* <JellyButton
         shape="round"
         bgType="white"
         append={<img src={downloadIcon} alt="엑셀다운로드" style={{ marginRight: "5px" }} />}
         title="엑셀 다운로드"
         onClick={handleShowDownloadDialog}
       >
         엑셀다운
       </JellyButton> */}
						<JellyButton
							shape="round"
							bgType="jellybook"
							shadow
							append={<img src={plusIcon} alt="고객 등록" />}
							title="고객 등록"
							onClick={handleShowAddDialog}
						>
							고객등록
						</JellyButton>
					</TableOptionWrap>
				</CustomerTop>
				<TableWrap>
					<Table
						columns={columns}
						dataSource={customerList}
						pagination={false}
						locale={{
							emptyText: (
								<NoDataWrap>
									<NoDataImg src={NodataCustomer} alt="No data" title="No data" />
									<NoDataTxt>등록된 고객이 없습니다.</NoDataTxt>
								</NoDataWrap>
							),
						}}
						onRow={(record) => {
							return {
								onClick: () => {
									if (record.customerUid) {
										dispatch({
											type: 'MODIFY',
											uid: record.customerUid,
										});
									}
								},
							};
						}}
					/>
					<Pagination
						defaultCurrent={1}
						current={customerPayload.page}
						total={totalCount}
						className="jellybook"
						onChange={(idx: number) => {
							setCustomerPayload({
								...customerPayload,
								page: idx,
							});
						}}
					/>
				</TableWrap>
			</CustomerContainer>
			{/* <GuideDownloadWrap>
        <GuideDownload href="/조리원용_앱연동안내.pdf" download>
          <GuideDownloadIcon src={downloadIcon} />
          젤리뷰 연동가이드
        </GuideDownload>
      </GuideDownloadWrap> */}
		</>
	);
};

const CustomerContainer = styled.section`
	border-radius: 5px;
	background-color: #fff;
`;

const CustomerTopNoti = styled.div`
	border-bottom: 1px solid #f6f1fe;
	padding: 10px 25px;
`;

const NotiText1 = styled.div`
	color: #ff0000;
	font-size: 25px;
`;
const NotiText2 = styled.div`
	color: #ff0000;
	margin-top: 10px;
	font-size: 18px;
`;

const CustomerTop = styled.div`
	height: 77px;
	border-bottom: 1px solid #f6f1fe;
	display: flex;
	padding: 0 25px;
	justify-content: space-between;
	align-items: center;
`;
const CountBox = styled.div`
	color: #151515;
	display: flex;
	align-items: center;
	transform: skew(0.05deg);
`;
const CountNumber = styled.div`
	font-size: 22px;
	font-weight: bold;
	margin-right: 5px;
`;
const CountTxt = styled.div`
	font-size: 12px;
`;

const TableOptionWrap = styled.div`
	display: flex;
	align-items: center;
`;

const TableWrap = styled.div`
	padding: 0 25px 61px 25px;
	position: relative;
`;

const NoDataWrap = styled.div`
	height: 530px;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
`;

const NoDataImg = styled.img`
	margin-bottom: 24px;
`;
const NoDataTxt = styled.p`
	color: #bababa;
	font-weight: 400;
	transform: skew(0.05deg);
`;

export default Customer;

const SearchWrap = styled.div`
	height: 100%;
	flex: 1;
	padding: 0 87px 0 52px;
	align-items: center;
	position: relative;
	&:before {
		position: absolute;
		left: 23px;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		content: '';
		width: 20px;
		height: 20px;
		background-image: url(${searchIcon});
		background-size: 17px;
		background-repeat: no-repeat;
		background-position: center;
	}
`;
const SearchInput = styled.input`
	height: 100%;
	width: 100%;
	outline: none;
	transform: skew(1deg);
	color: #151515;
	font-weight: bold;
	::placeholder {
		color: #d5d5d5;
	}
`;

const SearchKeywordReset = styled.button`
	width: 15px;
	height: 15px;
	position: absolute;
	right: 22px;
	top: 50%;
	transform: translateY(-50%);
	background-image: url(${keywordDeleteIcon});
	background-size: 13px;
	background-repeat: no-repeat;
	background-position: left center;
	cursor: pointer;
`;
